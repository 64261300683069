import { React, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Drawer, Toolbar, List,
    Typography,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Collapse,
    AppBar as MuiAppBar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import {PersonOutline  } from '@mui/icons-material';
import TableViewIcon from '@mui/icons-material/TableView';
import SourceIcon from '@mui/icons-material/Source';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import CallIcon from '@mui/icons-material/Call';

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function Navbar(props) {
    const { open, setOpen, admin } = props;
    const theme = useTheme();
    const location = useLocation();
    const [isMasters, setIsMasters] = useState(false);
    const [isContent, setIsContent] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isSetting, setIsSetting] = useState(false);
    const permissions = admin?.Role?.permissions;
    // const [permissions] = useState(JSON.parse(admin?.Role?.permissions || "[]"));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setIsMasters(!isMasters);
    };

    const handleClickContent = () => {
        setIsContent(!isContent);
    };
    const handleClickUser = () => {
        setIsUser(!isUser);
    };
    const handleClickSetting = () => {
        setIsSetting(!isSetting);
    };



    const isActive = (path) => {
        return location.pathname === path;
    };




    return (
        <Box >

            <AppBar
                open={open}
                position='relative'

                sx={{
                    marginTop: '57px',
                    bgcolor: 'white',
                    color: 'black',
                    boxShadow: 1
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        sx={{ mr: 2, ...(!open && { display: 'none' }) }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"

                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon onClick={handleDrawerOpen} />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Super Admin Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    scrollbarWidth: 'none',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        scrollbarWidth: 'none',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >

                <Divider />
                {Array.isArray(permissions) && permissions.length > 0 && (
                    <List
                        sx={{
                            marginTop: '57px',
                        }}
                    >

                        <ListItemButton onClick={handleClick}>
                            <ListItemIcon>
                                <TableViewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Masters" />
                            {isMasters ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isMasters} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {permissions[0]?.view && (
                                    <Link to={'/booking-status'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/booking-status') ? '#FD226E' : '',
                                            pl: 2,
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{
                                                    color: isActive('/booking-status') ? '#FFF' : '',
                                                    '&:hover': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                    },
                                                }}>Booking Status</Typography>}
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[1]?.view && (
                                    <Link to={'/country'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/country') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{
                                                    color: isActive('/country') ? '#FFF' : '',
                                                    '&:hover': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                    },
                                                }}>Country</Typography>} />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[2]?.view && (
                                    <Link to={'/state'} style={{ textDecoration: 'none' }}>
                                    <ListItemButton sx={{
                                        bgcolor: isActive('/state') ? '#FD226E' : '',
                                        pl: 2
                                    }}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography sx={{
                                            color: isActive('/state') ? '#FFF' : '',
                                            '&:hover': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}>State</Typography>} />
                                    </ListItemButton>
                                    </Link>
                                )}
                                {permissions[3]?.view && (
                                    <Link to={'/city'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/city') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={
                                                <Typography sx={{
                                                    color: isActive('/city') ? '#FFF' : '',
                                                    '&:hover': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                    },
                                                }}>City</Typography>
                                            } />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[4]?.view && (
                                    <Link to={'/amenities-type'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/amenities-type') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/amenities-type') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Amenities Type</Typography>
                                                } 
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[5]?.view && (
                                    <Link to={'/amenities'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/amenities') ? '#FD226E' : '',
                                            pl: 2
                                        }}>

                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/amenities') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Amenities</Typography>
                                                }
                                            />
                                            
                                        </ListItemButton>
                                    </Link>
                                )}
                                <Link to={'/room-amenities'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/room-amenities') ? '#FD226E' : '',
                                            pl: 2
                                        }}>

                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/room-amenities') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Room Amenities</Typography>
                                                }
                                            />
                                            
                                        </ListItemButton>
                                    </Link>
                                {permissions[6]?.view && (
                                    <Link to={'/language'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/language') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/language') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Language</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[7]?.view && (
                                    <Link to={'/listing-type'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/listing-type') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/listing-type') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Listing Type</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[8]?.view && (
                                    <Link to={'/property-type'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/property-type') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/property-type') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Property Type</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[9]?.view && (
                                    <Link to={'/room-type'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/room-type') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/room-type') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Room Type</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[10]?.view && (
                                    <Link to={'/tag'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/tag') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/tag') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Tag</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                        {(permissions[11]?.view || permissions[12]?.view) && (
                            <ListItemButton onClick={handleClickContent}>
                                <ListItemIcon>
                                    <SourceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Content" />
                                {isContent ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        )}
                        <Collapse in={isContent} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {permissions[11]?.view && (
                                    <Link to={'/faq'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/faq') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/faq') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>FAQ</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[12]?.view && (
                                    <Link to={'/page'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/page') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/page') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Page</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                        {permissions[13]?.view && (
                            <Link to={'/property-owners'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/property-owners') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <PeopleAltOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/property-owners') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Property Owners</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {permissions[14]?.view && (
                            <Link to={'/property'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/property') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/property') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Properties</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {permissions[15]?.view && (
                            <Link to={'/booking-table'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/booking-table') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <PersonOutline />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/booking-table') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Bookings</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {permissions[16]?.view && (
                            <Link to={'/booking-calendar'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/booking-calendar') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <CalendarMonthOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/booking-calendar') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Booking Calendar</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {(permissions[17]?.view || permissions[18]?.view) && (
                            <ListItemButton onClick={handleClickUser}>
                                <ListItemIcon>
                                    <SourceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                                {isUser ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        )}
                        <Collapse in={isUser} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {permissions[17]?.view && (
                                    <Link to={'/sub-admin'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/sub-admin') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/sub-admin') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Sub Admin</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[18]?.view && (
                                    <Link to={'/customers'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/customers') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                                {/* <PersonOutline /> */}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/customers') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Customers</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}

                            </List>
                        </Collapse>
                        {permissions[19]?.view && (
                            <Link to={'/documents'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/documents') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <DocumentScannerOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/documents') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Documents</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        <Link to={'/contact-us'} style={{ textDecoration: 'none' }}>
                            <ListItemButton sx={{
                                bgcolor: isActive('/contact-us') ? '#FD226E' : '',
                                pl: 2
                            }}>
                                <ListItemIcon>
                                    <CallIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography sx={{
                                            color: isActive('/contact-us') ? '#FFF' : '',
                                            '&:hover': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                        }}>Contact Us</Typography>
                                    }
                                />
                            </ListItemButton>
                        </Link>

                        <ListItemButton onClick={handleClickSetting}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Setting" />
                            {isSetting ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isSetting} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'/profile-setting'} style={{ textDecoration: 'none' }}>
                                    <ListItemButton sx={{
                                        bgcolor: isActive('/profile-setting') ? '#FD226E' : '',
                                        pl: 2
                                    }}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography sx={{
                                                    color: isActive('/profile-setting') ? '#FFF' : '',
                                                    '&:hover': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                    },
                                                }}>Profile</Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Link>
                                {permissions[20]?.view && (
                                    <Link to={'/role'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/role') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                                {/* <PersonOutline /> */}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/role') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Roles</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                <Link to={'/razorpay-setting'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/razorpay-setting') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                                {/* <PersonOutline /> */}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/razorpay-setting') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Razorpay Auth</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                            </List>
                        </Collapse>
                    

                    </List>
                )}
            </Drawer>

        </Box>
    );
}